import React, { forwardRef, useImperativeHandle } from "react";
import _ from "lodash";
import axios from "axios";
import { useLocalStorage } from "../components/hooks";

export default forwardRef(({ children }, ref) => {

    const [entityCache, setEntityCache] = useLocalStorage('oai-sync.cache.entities.exhibitedStatus', {});
    useImperativeHandle(ref, () => ({
        filteredEntities: async (entities) => {
            return await asyncFilter(entities, filterFn);
        },

        updateExhibitedStatus: async (entities) => {
            const rv = Promise.all(entities.map(async entity => {
                const oaiRole = _.find(entity.Roles, { strapi_component: 'role.oai-config', syncIsExhibited: true });
                if (oaiRole?.identifier) {

                    const oaiIsExhibited = await filterFn(entity);
                    if (oaiIsExhibited !== undefined) {//only update if we get true/false, else we preserve what we have from cms
                        oaiRole.isExhibited = oaiIsExhibited;
                    }
                    entityCache[oaiRole.identifier] = oaiIsExhibited;
                }
                return entity;
            }));
            setEntityCache(entityCache);
            return rv;
        },

        updateExhibitedStatusCached: (entities) => {
            entities.map(entity => {
                const oaiRole = _.find(entity.Roles, { strapi_component: 'role.oai-config', syncIsExhibited: true });
                if (oaiRole?.identifier && entityCache[oaiRole?.identifier]) {
                    oaiRole.isExhibited = entityCache[oaiRole.identifier];
                }
            });
            return entities;
        }
    }));

    return <>{children}</>
});

const asyncFilter = async (arr, predicate) =>
    Promise.all(arr.map(predicate))
        .then((results) =>
            arr.filter((_v, index) => results[index]
            ));

const getRequestUrl = (entity) => `${process.env.GATSBY_OAI_SYNC_URL}/entities/oai/isExhibited/${entity.strapiId || entity.id}`;

const filterFn = async entity => {
    const oaiRole = _.find(entity.Roles, { strapi_component: 'role.oai-config', syncIsExhibited: true });
    if (oaiRole?.identifier) {
        if (process.env.GATSBY_OAI_SYNC_URL) {
            const requestUrl = getRequestUrl(entity);

            let response;
            try {
                response = await axios.get(requestUrl);
            } catch (e) {
            }
            console.log(response);
            return response ? (response?.data === false ? false : true) : undefined;//only mark as not exhibited if we get an explicit false here
        }

    }
    return;//undefined if not configured
};
